.homebg {
  background-image: url(../../assets/home-bg.png);
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: black;
  width: 100%;
  height: 100vh;
  position: relative;
  font-family: Audiowide;
  color: white;
  .welcome {
    position: absolute;
    top: 800px;
    left: 450px;

    font-size: 24px;

    display: flex;
    align-items: center;
    span {
      margin: 0 10px;
    }
    .arrow {
      margin-left: 10px;
      height: 30px;
    }
  }
  .info-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 100px;
    left: 1250px;
    font-size: 20px;
    .top {
      margin-bottom: 25px;
    }
  }
  .box {
    text-decoration: none;
    position: absolute;
    top: 310px;
    left: 1150px;
    width: 300px;
    height: 65px;
    font-size: 24px;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2bb900;
    border-radius: 14px;
  }

  .box-flex {
    text-decoration: none;
    position: absolute;
    width: fit-content;
    min-width: 400px;
    max-width: 400px;
    height: fit-content;
    min-height: 65px;
    font-size: 24px;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #2bb900;
    border-radius: 14px;
  }

  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
  a:focus {
    text-decoration: none;
    color: white;
  }
  a:active {
    text-decoration: none;
    color: white;
  }
}
