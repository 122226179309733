.race-dog {
  position: relative;
  height: 42px;
  z-index: 3;
  transform-origin: top right;
  .body {
    position: absolute;
    top: 0;
    left: 0;
  }
  .vest {
    position: relative;
    top: 4px;
    left: 48px;
    .number {
      position: absolute;
      top: -1px;
      left: 2px;
    }
    .color {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
