.footer {
  background: black;
  padding: 50px var(--content-padding);
  padding-bottom: 50px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  .logo-copyright {
    align-self: flex-end;
    img {
      margin-bottom: 20px;
    }
  }
  .group-1 {
    display: flex;
    flex-direction: column;
    margin: 0 250px;
    .contact {
      display: flex;
      justify-content: center;
      color: white;
      font-family: Arial;
      font-weight: 600;
      font-size: 26px;
      text-decoration: none;
      margin: 20px 0;
      margin-left: 25px;
    }
    .legals {
      display: flex;
      justify-content: center;
      color: white;
      font-family: Arial;
      font-weight: 400;
      font-size: 18px;
      text-decoration: none;
      margin-top: 15px;
      a,
      a:link,
      a:visited,
      a:focus,
      a:hover,
      a:active,
      span {
        color: white;
        margin: 0 25px;
        text-decoration: none;
      }
    }
    .link-container {
      display: flex;
      flex-direction: row;
      .about {
        display: flex;
        flex-direction: column;
        h2 {
          letter-spacing: 1px;
          color: var(--main-green);
          font-family: Audiowide;
          font-weight: 600;
        }
        a {
          color: white;
          font-family: Arial;
          font-weight: 500;
          letter-spacing: 1px;
          font-size: 16px;
          text-decoration: none;
          margin: 7px 0;
        }
      }

      .social {
        margin-left: 150px;
        display: flex;
        flex-direction: column;
        h2 {
          letter-spacing: 1px;
          color: var(--main-green);
          font-family: Audiowide;
          font-weight: 600;
        }
        .content {
          display: flex;
          letter-spacing: 0.5px;
          .left {
          }
          .right {
            margin-left: 75px;
          }
          .media-link {
            cursor: pointer;
            display: flex;
            align-items: center;

            color: white;
            font-family: Arial;
            font-weight: 500;
            font-size: 16px;
            text-decoration: none;
            margin: 7px 0;
            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
