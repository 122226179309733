.bg-sponsor {
  height: 720px;
  width: 1200px;
  background: linear-gradient(
    to bottom,
    rgb(88, 88, 88) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
  left: calc(50% - 600px);
  top: calc(50% - 360px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .sponsor-msg {
      font-family: Audiowide;
      font-size: 32px;
      color: white;
      margin-top: 50px;
    }
    img {
      margin-top: 50px;

      width: 75%;
    }
  }
  .logo {
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 70px;
  }
}
