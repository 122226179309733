.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.register-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-row {
  display: flex;
  margin: 10px 0;
}

.acc-container {
  display: flex;
  justify-content: center;
}
.divider {
  width: 2px;
  height: 250px;
  background-color: #ccc;
  margin: 0 50px;
}
.success {
  color: green;
}
.error {
  color: red;
}
