//racelist
@font-face {
  src: local();
  font-family: source-sans-pro, sans-serif;

  font-weight: 400;

  font-style: normal;
}

//?????
.racelist {
  flex-grow: 1;
  background-color: black;
  .topbar {
    height: 150px;

    background: black;
    color: var(--main-green);
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    .sides {
      font-family: Arial;
      font-weight: 600;
      margin: 0 25px;
      padding-top: 15px;
      min-width: 50px;
      white-space: nowrap;
      text-decoration: none;
    }
    .next {
      display: flex;
      width: 100%;
      align-items: center;
      .box {
        margin: 0 25px;
        padding: 15px 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 225px;
        height: 110px;
        background: linear-gradient(to bottom, #ffffff -200%, #000000 100%);
        border: 1px solid var(--main-green);
        border-radius: 15px;
        color: rgb(255, 255, 255);
        font-family: Audiowide;
        font-weight: 400;
        font-size: 20px;
        transition: all ease 300ms;
        cursor: pointer;
        &:hover {
          background: rgb(66, 66, 66);
          color: white;
        }
        .race-name {
          font-family: Audiowide;
          font-weight: 400;
          font-size: 24px;
        }
        .time {
          display: flex;
          width: 100%;

          align-items: center;
          letter-spacing: 0.5px;
          font-family: Arial;
          font-weight: 600;
          .start {
            color: yellow;
            font-style: normal;
          }
          .clock {
            margin-left: 10px;
            white-space: nowrap;
            color: var(--main-green);
            font-style: italic;
            font-size: 1vw;
          }
        }
      }
    }
  }
  .race-classes {
    display: flex;
    align-items: center;
    padding: 0 25px;
    height: 100px;

    background: rgb(54, 54, 54);
    .title {
      font-family: Audiowide;
      font-size: 24px;
      color: white;
      display: flex;

      align-items: center;
      img {
        margin-left: 10px;
      }
    }
    .classes {
      margin-left: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .class-filter {
        margin: 0 5px;
        height: 50px;
        width: 96px;
        background-repeat: no-repeat;
        background-position: center center;
        color: white;
        font-family: Arial;
        font-weight: 600;
        background-image: url("../../assets/class-filter-bg.png");
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          -webkit-filter: brightness(80%);
          transition: all 300ms ease;
        }
      }
    }
  }
  .list {
    position: relative;
    // height: 100%;
    //TODO: scroll
    .titles {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      div:first-child {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      padding: 10px 25px;
      background: black;
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;
      color: var(--main-green);
      text-transform: uppercase;
      display: grid;
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr 3fr;
    }
    .race-row {
      padding: 10px 25px;
      display: grid;
      grid-template-columns: 4fr 3fr 3fr 3fr 3fr 3fr;
      background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
      height: 80px;
      text-decoration: none;
      .race-titles {
        font-family: Audiowide;
        font-size: 20px;
        color: white;
        font-weight: 600;
        display: flex;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        span:last-child {
          font-family: Arial;
          color: var(--main-green);
        }
      }
      .grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Arial;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 1px;
      }

      .distance {
        color: #00a128;
      }
      .entry-fee {
        color: rgb(255, 251, 0);
      }
      .prize-pool {
        color: #00a128;
      }
      .registered-racers {
        color: white;
      }
    }
    .races {
      overflow: scroll;
      height: calc(100% - 20px);
      background: black;
      color: white;
      .none {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .race-row {
        padding: 10px 25px;
        display: grid;
        grid-template-columns: 4fr 3fr 3fr 3fr 3fr 3fr;
        background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
        height: 80px;
        text-decoration: none;
        .race-titles {
          font-family: Audiowide;
          font-size: 20px;
          color: white;
          font-weight: 600;
          display: flex;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;
          span:last-child {
            font-family: Arial;
            color: var(--main-green);
          }
        }
        .grid-item {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: Arial;
          font-weight: 600;
          text-decoration: none;
          letter-spacing: 1px;
        }

        .distance {
          color: #00a128;
        }
        .entry-fee {
          color: rgb(255, 251, 0);
        }
        .prize-pool {
          color: #00a128;
        }
        .registered-racers {
          color: white;
        }
      }
    }
  }
}
.clipboard {
  margin-left: 24px;
  width: 28px;
}
.race-detail {
  flex-grow: 1;
  background: black;
  .grid-5 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr 3fr;
    width: 1500px;
    div {
      text-align: left;
    }
  }
  .titles {
    div:first-child {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    padding: 10px 25px;
    background: black;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
    color: var(--main-green);
    text-transform: uppercase;
  }
  .info-bar {
    height: 100px;
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .trackname {
      font-family: Audiowide;
      font-size: 24px;
      color: white;
      font-weight: 600;
      display: flex;
      align-items: center;
    }
    .planet {
      font-family: Arial;
      font-size: 20px;
      font-weight: 600;
      color: var(--main-green);
      font-style: italic;
      display: flex;
      align-items: center;
    }
    .track-conditions {
      font-family: Arial;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: grey;
      font-style: italic;
      display: flex;
      align-items: center;
    }
    .sponsor {
      display: flex;
      padding-right: 5px;
      max-width: 500px;
      box-sizing: border-box;
      .msg {
        max-width: 250px;
        overflow-wrap: break-word;
        font-family: Arial;
        font-size: 16px;
        font-weight: 600;
        color: white;
        font-style: italic;
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
      img {
        width: 150px;
        object-fit: contain;
      }
    }
    .wrapper {
      display: flex;
      margin-right: 80px;
      .watch-3d {
        justify-self: flex-end;
        cursor: pointer;
        font-family: Arial;
        font-size: 20px;
        font-weight: 600;
        color: rgb(255, 251, 0);
        font-style: italic;
        border: 2px solid var(--main-green);
        padding: 8px 20px;
        border-radius: 2px;

        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-left: 30px;
        }
      }
      .live {
        justify-self: flex-end;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
        font-family: Arial;
        font-size: 20px;
        font-weight: 600;
        color: white;
        font-style: italic;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .extra-info-bar {
    height: 60px;
    background: rgb(54, 54, 54);
    display: flex;
    align-items: center;
    .event-type {
      margin-left: 10px;
    }
    .info {
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--main-green);
      font-family: Arial;
      font-size: 16px;
      font-weight: 600;
      span {
        margin-left: 10px;
        color: white;
      }
    }
  }
  .blackbar {
    background: black;
    height: 40px;
  }
  .race-area {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -150%,
      rgba(0, 0, 0, 1) 100%
    );
    padding: 20px 40px;
    .sections {
      display: flex;

      .positions {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .icon {
          font-weight: 600;
          font-family: Arial;
          font-size: 18px;
          width: 55px;
          height: 55px;
          border-radius: 27.5px;
          background: black;
          border: 2px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          color: white;
        }
        .first {
          color: black;
          border: 2px solid black;
          background: gold;
        }
        .second {
          color: black;
          border: 2px solid gold;
          background: silver;
        }
        .third {
          color: black;
          border: 2px solid gold;
          background: rgb(255, 145, 0);
        }
      }

      .racers {
        position: relative;
        width: 450px;

        .row {
          display: flex;
          // margin: 10px 0;
          position: absolute;
          transition: ease all 0.3s;
          .dog {
            transition: ease 300ms all;
            width: 400px;
            height: 55px;
            position: relative;
            background: black;
            border: 2px solid var(--main-green);
            border-radius: 3px;
            margin-left: 20px;
            overflow: hidden;
            .img-container {
              overflow: hidden;
              max-width: 90px;
              img {
                vertical-align: bottom;
                height: 55px;
                object-fit: cover;
                transform-origin: 15% 5%;
                scale: 200%;
              }
            }

            .divider {
              position: absolute;
              left: 0px;
              background: none;
              height: 55px;
              width: 56px;
            }
            .name-type {
              width: 55%;
              position: absolute;
              top: 5px;
              left: 94px;
              font-family: Arial;
              .name {
                font-weight: 600;
                padding-left: 15px;
                padding-bottom: 5px;
                color: white;
                border-bottom: 1px solid var(--main-green);
              }
              .type {
                font-weight: 600;
                padding-left: 15px;
                padding-top: 5px;
                color: grey;
              }
            }
            .number {
              position: absolute;
              top: 5%;
              left: 80%;
              font-weight: 600;
              font-size: 32px;
              font-style: italic;
              color: var(--main-green);
            }
          }
        }
      }
    }
  }
}
.event-type {
  //background-image: url("../../assets/class-bg.png");

  //background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--main-green);
  border-radius: 2px;
  width: 72px;
  height: 34px;
  color: white;
  font-family: Arial;
  font-weight: 600;
}
.countdown {
  color: var(--main-green);
  font-family: Audiowide;
  font-size: 90px;
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  z-index: 10;
  opacity: 1;
  transition: visibility 1s, opacity 1s linear;
}

.grad-bottom {
  background: linear-gradient(to bottom, #ffffff71 -300%, #000000 100%);
  flex: 1 1 auto;
  width: 100%;
}
.race-summary {
  color: white;
  display: flex;
  .onefour {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .fiveeight {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .position-row {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
    .position-index {
      color: yellow;
      // font-family: Arial;
      font-size: 36px;
      padding: 20px 40px;
      font-variant-numeric: ordinal;
      width: 115px;
      box-sizing: border-box;
      font-family: source-sans-pro;
    }
    .position-info {
      width: 100%;
      height: 100%;
      margin-right: 10px;
      display: flex;
      align-items: center;
      font-family: Audiowide;
      .img-container {
        height: 100%;
        max-width: 200px;
        overflow: hidden;
        img {
          max-height: 100px;
          scale: 200%;
          transform-origin: 20% 5%;
        }
      }

      .detail {
        width: 100%;
        height: 100%;

        justify-content: center;
        flex-direction: column;
        .top {
          display: grid;
          grid-template-columns: 1fr 5fr 4.2fr;
          // display: flex;
          height: 50%;
          box-sizing: border-box;

          border-bottom: 1px solid grey;
          padding-bottom: 10px;
          position: relative;

          img {
            width: 40px;
            height: 40px;
            position: relative;
            top: -2px;
            left: -2px;
          }

          .top-1 {
            .name {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              font-size: 20px;
              text-align: center;
            }

            display: flex;
            justify-content: center;
          }
          .top-2 {
            display: flex;
            margin-left: 30px;
            .extra {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              font-size: 22px;
            }
            .time {
              display: flex;
              font-size: 22px;
              font-family: Arial;
              align-items: flex-end;
              color: yellow;
              margin-left: 10px;
            }
          }
        }
      }
      .bottom {
        display: grid;
        grid-template-columns: 1fr 5fr 4.2fr;
        height: 50%;
        box-sizing: border-box;

        padding-top: 10px;
        border-top: 1px solid grey;
        font-family: Arial;
        font-weight: 600;
        .bot-1 {
          display: flex;
          justify-content: center;

          .bloodline {
            font-size: 20px;
            color: grey;
            text-transform: uppercase;
          }
        }
        .bot-2 {
          display: flex;
          margin-left: 30px;
          .bloodline {
            font-size: 20px;
            color: grey;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
