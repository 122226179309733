.menu {
  position: sticky;
  top: 0px;
  width: 300px;
  min-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;

  background: linear-gradient(to bottom, #ffffff -500%, #000000 100%);
  border-right: 1px solid grey;
  .option {
    font-family: Audiowide;

    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(to bottom, #ffffff -300%, #000000 100%);
    cursor: pointer;
    &:hover {
      background: #000000;
      color: var(--main-green);
    }
  }
  .logo {
    position: absolute;
    height: 80px;

    top: 80%;
    left: calc(50% - 40px);
  }
}
