.settings-bg {
  height: 700px;
  width: 1000px;
  background: linear-gradient(
    to bottom,
    rgb(88, 88, 88) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
  left: calc(50% - 500px);
  top: calc(50% - 350px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid grey;

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 60px 100px;
    .title {
      font-family: Audiowide;
      font-size: 36px;
      color: white;
      margin-top: 50px;
      margin-left: 5px;
    }

    .input-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-top: 40px;
      .input-label {
        font-family: Arial;
        font-size: 24px;
        font-weight: 600;
        color: grey;
        margin-left: 5px;
      }
      input {
        outline: none;
        color: white;

        margin-top: 10px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid white;
        background-color: rgba(128, 128, 128, 0.658);
        &::placeholder {
          color: white;
        }
      }
    }
    .conditional {
      margin-top: 10px;
      margin-left: 5px;
      text-align: center;
      font-size: 16px;
      color: white;
    }

    .error {
      color: red;
    }
  }
  .kennel-image {
    position: absolute;
    top: 28%;
    right: 15%;
    display: flex;
    flex-direction: column;

    span {
      font-family: Arial;
      font-size: 24px;
      font-weight: 600;
      color: grey;
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }
  .logo {
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 70px;
  }
}
.button-row {
  margin-top: 50px;
}
.save {
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 4px;
  background-color: var(--main-green);
  padding: 10px 10px;
  width: 130px;
  height: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;
  &:hover {
    cursor: pointer;
    background-color: #00ff0088;
  }
  &:disabled {
    background-color: black;
  }
}
.discard {
  margin-left: 40px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 4px;
  border: 2px solid var(--main-green);
  background-color: transparent;
  padding: 10px 10px;
  width: 130px;
  height: 45px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;
  &:hover {
    cursor: pointer;
    background-color: #00ff000a;
  }
  &:disabled {
    background-color: black;
  }
}
