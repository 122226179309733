.bg {
  height: 900px;
  width: 750px;
  background: linear-gradient(
    to bottom,
    rgb(88, 88, 88) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  position: relative;
  left: calc(50% - 375px);
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;

    cursor: pointer;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 475px;
    padding: 20px;
    .title {
      font-family: Audiowide;
      font-size: 36px;
      color: white;
      margin-top: 50px;
    }
    .token {
      margin-top: 75px;
      max-width: 100%;
    }
    .input-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-top: 40px;
      .input-label {
        font-family: Arial;
        font-size: 16px;
        font-weight: 600;
        color: white;
        margin-left: 5px;
      }
      input {
        outline: none;
        color: white;

        margin-top: 5px;
        width: 100%;
        height: 45px;
        border-radius: 4px;
        padding: 0 10px;
        box-sizing: border-box;
        border: 1px solid white;
        background-color: rgba(128, 128, 128, 0.658);
      }
    }
    .conditional {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      color: grey;
    }
    .extra-notes {
      margin-top: 20px;
      color: white;
      .point {
        margin: 10px 0;
      }
    }

    .error {
      color: red;
    }
  }
  .logo {
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 70px;
  }
}
.save {
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  outline: none;
  border-radius: 2px;
  background-color: var(--main-green);
  padding: 10px 10px;
  width: 110px;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all ease 300ms;
  &:hover {
    cursor: pointer;
    background-color: #00ff0088;
  }
}
