.kennel-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .top-tag {
    background: black;
    font-family: Arial;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center; /* align vertical */
    height: 30px;
    padding: 0.25em 250px;
    justify-content: center;
    border-bottom: 2px solid rgb(131, 131, 131);
    span {
      margin-right: auto;
      cursor: pointer;
    }
  }
  .kennel-header {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    height: 100px;
    font-family: Audiowide;
    font-weight: 600;
    font-size: 32px;
    padding-left: 250px;
    //linear gradient background from top to bottom with 2 colors
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -200%,
      rgba(0, 0, 0, 1) 100%
    );
    .picture {
      position: absolute;
      top: -20px;
      left: 120px;
      z-index: 2;
    }
    .clipboard {
      margin-left: 24px;
      width: 28px;
    }
    .settings {
      cursor: pointer;
      margin-left: auto;
      margin-right: 200px;
      display: flex;
      font-size: 20px;
      color: grey;
      transition: ease all 300ms;
      img {
        margin-right: 10px;
        width: 28px;
      }
      &:hover {
        filter: brightness(150%);
      }
    }
  }

  .kennel-content {
    .detail-header {
      margin-top: 30px;
      background: black;
      color: white;
      height: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Audiowide;
      font-weight: 600;
      font-size: 36px;
      padding-left: 150px;
      padding-right: 350px;
      .dog-class {
        font-family: Arial;
        font-size: 24px;
      }
    }
    .kennel-detail {
      display: flex;
      padding: 75px 0;
      .detail-content {
        display: flex;
        flex-direction: column;
        margin: 0 150px;
        margin-right: 50px;
        .dog-image {
          margin-bottom: 25px;
          width: min-content;
          border: 2px solid grey;
          overflow: hidden;
          box-sizing: border-box;
          img {
            vertical-align: bottom;
            object-fit: cover;
            height: 300px;
            width: 500px;
          }
        }
        .extra-icons {
          display: flex;

          margin-top: 50px;

          color: grey;
          font-size: 24px;
          width: 100%;

          .group {
            margin-left: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              margin-left: 10px;
            }
          }
          .group:nth-child(2) {
            margin-left: 100px;
          }
        }
      }
      .detailed-info {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        .detail {
          display: flex;
          flex-direction: column;
          margin: 0 30px;
          margin-bottom: 50px;
          .title {
            font-family: Audiowide;
            font-size: 24px;
            color: grey;
            margin-bottom: 12px;
          }
          .value {
            display: flex;
            font-family: Arial;
            font-size: 24px;
            color: white;
            m .highlight {
              display: flex;
              align-items: center;
              img {
                margin-left: 6px;
              }
            }
          }
        }
      }
    }
  }
  .kennel-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: Arial;

    font-weight: 600;
    position: relative;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.651) -150%,
      rgba(0, 0, 0, 1) 100%
    );
    .logo-shadow {
      position: absolute;
      top: 80%;
      left: 90%;
    }
    .stat-header {
      display: grid;
      grid-template-columns: repeat(3, 300px);
      align-items: center;
      height: 50px;
      color: white;
      padding-left: 250px;
      font-size: 20px;
    }
    .stat-info {
      display: grid;
      grid-template-columns: repeat(3, 300px);
      align-items: center;
      padding-left: 250px;
      background: black;
      height: 50px;

      font-size: 20px;
    }

    .holder {
      position: relative;
      padding-right: 300px;
      .kennel-info {
        margin: 30px 100px;
        margin-bottom: 0;
        margin-right: 0;
        color: grey;
        .kennel-info-header {
          font-size: 28px;
          margin-bottom: 8px;
        }
        .filters {
          display: flex;
          margin-bottom: 20px;

          .container-box {
            background: black;
            border: 3px solid #009c2f;
            border-radius: 5px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            font-size: 24px;
          }
          .search-icon {
            height: 36px;
            width: 36px;
          }
          .filter-icon {
            height: 36px;
            width: 36px;
            margin-right: 10px;
          }
          .downarrow-icon {
            height: 36px;
            width: 36px;
            margin-left: 20px;
          }
          .icon {
            filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(211deg)
              brightness(91%) contrast(90%);
          }
          input {
            width: 350px;
            background: none;
            color: var(--main-green);
            height: 24px;
            font-family: Arial;
            font-size: 24px;
            padding: 2px;
            border: none;
            margin: 0;
            margin-left: 10px;
            &:focus {
              outline: none;
            }
          }
          .filter {
            margin-left: 25px;
            width: 200px;
          }

          .sort {
            margin-left: auto;
            align-items: center;
            display: flex;
            color: grey;
            .sort-by {
              font-size: 24px;
              margin-right: 12px;
            }
            div {
              width: 250px;
            }
          }
        }
        .dogs {
          display: flex;
          width: 100%;

          flex-direction: column;
          .row {
            cursor: pointer;
            background: black;
            border: 3px solid #009c2f;
            border-radius: 5px;
            height: 80px;
            display: flex;

            align-items: center;
            cursor: pointer;
            margin-bottom: 15px;
            position: relative;
            .img-container {
              overflow: hidden;
              img {
                vertical-align: bottom;
                height: 80px;
                object-fit: cover;
                transform-origin: 15% 5%;
                scale: 200%;
              }
            }

            .divider {
              position: absolute;
              left: 37px;
              background: none;
              height: 80px;
              width: 56px;
            }
            .dog-name {
              margin-left: 20px;
              color: white;
              font-size: 30px;
            }
            .bloodline {
              font-size: 24px;
              margin-left: 40px;
              color: grey;
            }
            .fatigue {
              margin-left: auto;
            }
            .end {
              margin: 0 20px;
              color: white;
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

.fatigue {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 504px;

  color: grey;
  padding: 6px 10px;
  border: 2px grey solid;
  box-sizing: border-box;
  .gradient-slider {
    position: relative;
    width: 55%;
    height: 80%;
    min-height: 20px;
    border: 1px solid rgb(0, 199, 83);
    background: linear-gradient(to right, black -20%, rgb(0, 199, 83));
    .indicator {
      position: absolute;
      top: -5px;
      left: 5px;
      height: 150%;
      min-height: 20px;
      border-left: 1px solid rgb(0, 199, 83);
    }
  }
}
