.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 50px;
  //background color with linear gradient from black to light grey going up
  background: linear-gradient(
    to bottom,
    rgb(107, 107, 107) -100%,
    rgb(0, 0, 0) 100%
  );
  z-index: 50;

  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(77, 77, 77);

  .inner {
    position: relative;
    width: 100%;
    padding: 0 var(--content-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .link-group {
      font-family: Audiowide;
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      .connect-btn {
        background: var(--main-green);
        margin: 0 15px;
        &:disabled {
          background: black;
          color: white;
        }
      }
      .nav-link {
        margin: 0 15px;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
      .logo {
        cursor: pointer;
        margin-left: 15px;
        width: 140px;
      }
    }
  }
}
