.tokens {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container {
  overflow: scroll;
  .no-tokens-text {
    font-size: 20px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.733);
    margin-top: 20px;
    a {
      margin: 0 5px;
      text-decoration: none;
      color: var(--main-green);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .subtitle {
    font-family: Audiowide;
    font-size: 30px;
    color: rgb(255, 255, 255);
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 5px;
  }
}
.row-popup {
  display: flex;
  margin: 10px 0;
  transition: ease all 0.3s;
  .dog {
    transition: ease 300ms all;
    width: 400px;
    height: 55px;
    position: relative;
    background: black;
    border: 2px solid var(--main-green);
    border-radius: 3px;
    margin-left: 20px;
    overflow: hidden;
    .img-container {
      overflow: hidden;
      max-height: 100%;
      width: 90px;
      .join-dog {
        height: 100%;
        transform-origin: -1% 0%;
        scale: 10%;
        object-fit: cover;
        margin-top: none;
      }
    }

    .divider {
      position: absolute;
      left: 0px;
      background: none;
      height: 55px;
      width: 56px;
    }
    .name-type {
      width: 55%;
      position: absolute;
      top: 5px;
      left: 94px;
      font-family: Arial;
      .name {
        font-weight: 600;
        padding-left: 15px;
        padding-bottom: 5px;
        color: white;
        border-bottom: 1px solid var(--main-green);
      }
      .type {
        font-weight: 600;
        padding-left: 15px;
        padding-top: 5px;
        color: grey;
      }
    }
    .join-button {
      position: absolute;
      top: 22%;
      left: 82%;
      font-weight: 600;
      font-size: 16px;

      color: white;
      background: var(--main-green);
      padding: 5px 10px;
      cursor: pointer;
      &:hover {
        background: green;
      }
      &:disabled {
        background: grey;
        cursor: not-allowed;
      }
    }
  }
}
