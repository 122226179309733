:root {
  --historygrid: 2fr 4fr 4fr 3fr 5fr;
}

.history-row {
  color: white;
  &:active {
    color: white;
  }
  &:hover {
    color: white;
  }
  padding: 10px 25px;
  display: grid;
  grid-template-columns: var(--historygrid);
  background: linear-gradient(to bottom, #ffffff -700%, #000000 100%);
  height: 80px;
  text-decoration: none;
  .race-titles {
    font-family: Arial;
    font-size: 20px;
    color: white;
    font-weight: 600;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;
  }
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 1px;
  }
  .name {
    font-family: Audiowide;
    font-size: 22px;
  }
  .position {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    width: 55px;
    height: 55px;
    border-radius: 27.5px;
    border: 2px solid gold;
    color: gold;
    font-size: 22px;
    box-sizing: border-box;
    font-family: source-sans-pro;
  }
  .distance {
    color: #00a128;
    font-size: 16px;
  }
  .date {
    color: yellow;
  }
}
.history-list {
  .titles {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    padding: 10px 25px;
    background: black;
    font-family: Arial;
    font-size: 16px;
    font-weight: 600;
    color: var(--main-green);
    text-transform: uppercase;
    display: grid;
    grid-template-columns: var(--historygrid);
  }
}
